import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  borderComponent: {
    height: "10px",
    width: "180px",
    backgroundColor: "#FFCA49",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    gap: "18px",
  },
}));
type TitleProps = {
  color: string;
  title: string;
};

const Title: React.FC<TitleProps> = ({ color, title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.titleContainer}>
      <Box className={classes.borderComponent} />
      <Typography fontSize="42px" fontWeight="500" color={color} align="center" fontFamily="Urbanist">
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
