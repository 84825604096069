import React from "react";
import Banner from "./Elements/Banner/Banner";
import Features from "./Elements/Features";
import Values from "./Elements/Values/Values";
import Footer from "./Elements/Footer/Footer";
import Esignature from "./Elements/FeatureDetails/Esignature";
import Evault from "./Elements/FeatureDetails/Evault";
import Notarization from "./Elements/FeatureDetails/Notarization";
import Vconference from "./Elements/FeatureDetails/Vconference";
import Support from "./Elements/Support/Support";
import BlockchainTechnology from "./Elements/Blockchain/Blockchain";

const LandingPage = () => {
  return (
    <div>
      <Banner />
      <Features />
      <Values />
      <div id="#section1">
        <Esignature />
      </div>
      <div id="#section2">
        <Evault />
      </div>
      <div id="#section3">
        <Notarization />
      </div>
      <div id="#section4">
        <Vconference />
      </div>
      {/* <div id="#section5">
        <Blockchain />
      </div> */}
      <BlockchainTechnology />
      <Support />
      <Footer />
    </div>
  );
};

export default LandingPage;
