import { Box } from "@mui/material";
import React from "react";
import Title from "../../../ui/Title";
import ProductComponent from "./ProductComponent";

const Notarization = () => {
  return (
    <Box padding={{ xs: 4, md: 7 }}>
      <Box mb={{ xs: "120px", md: "1px" }}>
        <Title title="eNotarization" color="#000000" />
      </Box>
      <ProductComponent
        isImageLeft={true}
        image="/Images/Notary.png"
        title="Video Conferencing"
        description="Notarization is the process of authenticating the documents after detailed verification by a Notary. The DocVenture Digitized workflow process is an alternative way of doing the same instead of manual process. This workflow process allows to select a certified Notary Authority (Notary Public) as commissioned or appointed by the respective Federal State, to schedule an appointment to verify, witness, and attach legality to the contract or agreement document through Remote Online Notarization, which is permitted by many state laws. "
        description2="A Notarized document becomes Official and Valid when all eSignatures considered as legitimate"
      />
    </Box>
  );
};

export default Notarization;
