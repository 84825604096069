import { Box } from "@mui/material";
import React from "react";
import Title from "../../../ui/Title";
import ProductComponent from "./ProductComponent";

const Evault = () => {
  return (
    <Box py={0} padding={{ xs: 4, md: 7 }}>
      <Box mb={{ xs: "120px", md: "1px" }}>
        <Title title="eVault" color="#000000" />
      </Box>
      <ProductComponent
        isImageLeft={false}
        image="/Images/eVault (2).png"
        title="Secured Documents Vaulting"
        description="Every document created or uploaded should be protected in an eVault created for every user account. Similar to physical vault, which can be used to store and protect valuable physical assets, the eVault is virtual equivalent of safety deposit box to store and safeguard the critical documents at a centralized location thus preventing unauthorised access. Access is controlled through access rights granted to user in accordance with rules defined in the system."
        description2="Access is controlled by Administrator to restrict the access to the intended documents"
      />
    </Box>
  );
};

export default Evault;
