import React from "react";
import Title from "../../../ui/Title";
import { Box } from "@mui/material";
import ProductComponent from "./ProductComponent";
const Esignature = () => {
  return (
    <Box padding={{ xs: 4, md: 7 }}>
      <Box mb={{ xs: "120px", md: "1px" }}>
        <Title title="eSignature" color="#000000" />
      </Box>
      <ProductComponent
        isImageLeft={true}
        image="/Images/Esign.PNG"
        title="eSignatire for Digital Signing"
        description="Though the physical appearance and signing of the documents is still prevalent today, but to make it easy for executing any official agreement online, eSigning of the documents is an alternative way of doing. The eSignature allows to sign electronically by using an email notification received by all agreement holders through eSignature URL link, whenever the contract is prepared and loaded onto the DocVenture system. The document format may be PDF or Word"
        description2="An Electronic Signature is valid and complies with State Regulation and Act"
      />
    </Box>
  );
};

export default Esignature;
