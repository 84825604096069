import React from "react";

const BannerLogo = () => {
  return (
    <img
      src="./Images/doc van logo.svg"
      alt="logo"
      style={{ filter: "drop-shadow(10px 20px 8px #fff)", width: "70%" }}
    />
  );
};

export default BannerLogo;
