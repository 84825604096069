import React from "react";
import Title from "../../../ui/Title";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

const BlockchainTechnology = () => {
  return (
    <Box padding={{ xs: 4, md: 7 }} bgcolor="#00427a">
      <Title title="Why Blockchain" color="#eef7ff" />
      <Box width="100%" display={{ md: "block", xs: "none" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <img src="./Images/blockchain1.JPG" alt="blockchain" width="5%" />
        </Box>
      </Box>

      <Grid container mt={4} spacing={6}>
        <Grid md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <TaskAltRoundedIcon sx={{ color: "#fff" }} />
            <Typography variant="h5" color="#FFF" fontWeight="200">
              DocVenture runs on Blockchain to add more Security and
              Transparency
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <TaskAltRoundedIcon sx={{ color: "#fff" }} />
            <Typography variant="h5" color="#FFF" fontWeight="200">
              Fortifying eSignature and Notarization with Blockchain to prevent
              tampering
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <TaskAltRoundedIcon sx={{ color: "#fff" }} />
            <Typography variant="h5" color="#FFF" fontWeight="200">
              Digital Documents verified against its Authenticity and Ownership
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <TaskAltRoundedIcon sx={{ color: "#fff" }} />
            <Typography variant="h5" color="#FFF" fontWeight="200">
              Adds more immutability for the purpose of Auditing and
              Trustworthiness
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlockchainTechnology;
