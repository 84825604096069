import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, ReactNode } from "react";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "28px",
    width: "100%",
    height: "200px",
    borderRadius: "10px",
    transition: "0.5s ease-in-out",
    //border: "1px dotted #000000",
    padding: "20px",
    boxSizing: "border-box",
    color: "#ffffff",
  },
  title: {
    fontSize: "24px !important",
    color: "#fff",
    fontWeight: "800",
    fontFamily: "Poppins",
    textShadow: "2px 2px 2px #000000",
  },
  animatedButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "16px 36px",
    //border: "4px solid",
    // borderColor: "transparent",
    fontSize: "16px",
    backgroundColor: "",
    borderRadius: "100px",
    fontWeight: 600,
    color: "#1f387e",
    boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
    cursor: "pointer",
    overflow: "hidden",
    transition: "all 0.6s cubic-bezier(0.23, 1, 0.32, 1)",
    "&:hover": {
      boxShadow: "0 0 0 12px transparent",
      color: "#212121",
      borderRadius: "12px",
      "& $arr1": {
        right: "-25%",
      },
      "& $arr2": {
        left: "16px",
      },
      "& $text": {
        transform: "translateX(12px)",
      },
      "& svg": {
        fill: "#1f387e",
      },
      "& $circle": {
        width: "220px",
        height: "220px",
        opacity: 1,
      },
    },
    "&:active": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 4px greenyellow",
    },
  },
  arr1: {
    position: "absolute",
    width: "24px",
    fill: "#1f387e",
    zIndex: 9,
    transition: "all 0.8s cubic-bezier(0.23, 1, 0.32, 1)",
    right: "16px",
  },
  arr2: {
    position: "absolute",
    width: "24px",
    fill: "#1f387e",
    zIndex: 9,
    transition: "all 0.8s cubic-bezier(0.23, 1, 0.32, 1)",
    left: "-25%",
  },
  circle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20px",
    height: "20px",
    backgroundColor: "#b9e0fe",
    borderRadius: "50%",
    opacity: 0,
    transition: "all 0.8s cubic-bezier(0.23, 1, 0.32, 1)",
  },
  text: {
    position: "relative",
    zIndex: 1,
    transform: "translateX(-12px)",
    transition: "all 0.8s cubic-bezier(0.23, 1, 0.32, 1)",
    fontSize: "12px",
  },
}));
type FeaturesCardProps = {
  icon?: ReactNode;
  title: string;
  description: string;
  sectionId: string;
  image?: string;
};

const FeaturesCard: FC<FeaturesCardProps> = ({
  icon,
  title,
  description,
  sectionId,
  image,
}) => {
  const handleButtonClick = () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    console.log(section);
  };

  const classes = useStyles();
  return (
    <center>
      <Box
        className={classes.card}
        sx={{ background: `url(${image})`, backgroundSize: "cover" }}
      >
        {icon}
        <Typography className={classes.title} variant="h3" fontWeight={400}>
          {title}
        </Typography>
        <button className={classes.animatedButton} onClick={handleButtonClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.arr2}
            viewBox="0 0 24 24"
          >
            <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
          </svg>
          <span className={classes.text}>KNOW MORE</span>
          <span className={classes.circle}></span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.arr1}
            viewBox="0 0 24 24"
          >
            <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
          </svg>
        </button>
      </Box>
    </center>
  );
};

export default FeaturesCard;
