import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import Loader from "../../../ui/Loaders";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "28px",
    gap: "20px",
    width: "100%",
    boxSizing: "border-box",
    paddingTop: "14px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    width: "100%",
  },
  inputElement: {
    height: "42px",
    width: "100%",
    borderRadius: "6px",
    outline: "none",
    padding: "4px",
    fontFamily: "Poppins",
    paddingLeft: "12px",
    boxSizing: "border-box",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "-webkit-appearance": "none",
      appearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  button: {
    border: "none",
    outline: "none",
    background: "#2288ee",
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Poppins",
    width: "150px",
    padding: "10px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
  },
  textArea: {
    width: "100%",
    borderRadius: "6px",
    fontFamily: "Poppins",
    boxSizing: "border-box",
    padding: "10px",
    outline: "none",
  },
}));

type FormValues = {
  name: string;
  email: string;
  company: string;
  phNumber: number | null;
  message: string;
};

const ModalForm = ({
  setIsFormSubmitted,
  setOpen,
}: {
  setIsFormSubmitted: any;
  setOpen: any;
}) => {
  const [formDetails, setFormDetals] = useState<FormValues>({
    name: "",
    email: "",
    company: "",
    phNumber: null,
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formDetails.name.trim()) {
      setErrorMessage("Please enter Full Name");
      return;
    }

    if (!formDetails.email.trim()) {
      setErrorMessage("Please enter Email");
      return;
    } else if (!validateEmail(formDetails.email)) {
      setErrorMessage("Please enter a valid Email");
      return;
    }

    // if (!formDetails.phNumber) {
    //   setErrorMessage("Please enter phone number");
    //   return;
    // }

    setIsLoading(true);

    if ((formDetails.name, formDetails.email)) {
      emailjs
        .sendForm(
          "service_5ryua6g",
          "template_bzf4lih",
          event.target as HTMLFormElement,
          "hhwAL8gNITWypuRVD"
        )
        .then(
          (doc) => {
            console.log(doc);
            if (doc.status === 200) {
              setIsFormSubmitted(true);
              setIsLoading(false);
            }
          },
          (error: any) => {
            console.log("FAILED...", error.text);
          }
        );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormDetals({ ...formDetails, [event.target.name]: event.target.value });
    if (event.target.value) {
      setErrorMessage("");
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormDetals({ ...formDetails, [event.target.name]: event.target.value });
  };

  const handleClose = () => {
    setIsFormSubmitted(false);
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Box className={classes.container} width={{ md: "440px", xs: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
          width: "100%",
        }}
      >
        <button
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </Box>
      <Typography variant="h2" color="#007cf0">
        Let's Connect
      </Typography>
      <Typography variant="h6" align="center">
        Please complete this form and Submit
      </Typography>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter Full Name"
          name="name"
          onChange={handleChange}
          className={classes.inputElement}
          style={{ border: "1.5px solid #e4e6e6" }}
        />
        <input
          type="text"
          placeholder="Enter Email Id"
          name="email"
          onChange={handleChange}
          className={classes.inputElement}
          style={{ border: "1.5px solid #e4e6e6" }}
        />
        <input
          type="text"
          placeholder="Enter Company Name"
          name="company"
          onChange={handleChange}
          className={classes.inputElement}
          style={{ border: "1.5px solid #e4e6e6" }}
        />
        <input
          type="number"
          placeholder="Enter Phone Number"
          name="phNumber"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
          onChange={handleChange}
          className={classes.inputElement}
          style={{
            border: "1.5px solid #e4e6e6",
          }}
        />
        <textarea
          rows={4}
          onChange={handleTextAreaChange}
          name="message"
          className={classes.textArea}
          style={{ border: "1.5px solid #e4e6e6" }}
          placeholder="Please provide your requirement"
        />
        {errorMessage && (
          <Typography variant="h6" fontSize={18} align="left" color="#ff2323">
            {errorMessage}
          </Typography>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <button className={classes.button} type="submit">
            Send Request
          </button>
        )}
      </form>
    </Box>
  );
};

export default ModalForm;
