import DrawIcon from "@mui/icons-material/Draw";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import React from "react";

export const featuresCardElements = [
  {
    icon: <DrawIcon sx={{ color: "#2091F9", fontSize: "28px" }} />,
    title: "eSignature",
    sectionId: "#section1",
    img: "./Images/esign_small.JPG",
    description:
      "DocVenture simplify digital signing among parties across USA from anywhere without physical presence",
  },
  {
    icon: <DescriptionIcon sx={{ color: "#2091F9", fontSize: "42px" }} />,
    title: "eNotarization",
    sectionId: "#section3",
    img: "./Images/notary_small.JPG",
    description:
      "Enabling the workflow process to certify agreement and attach legality as per Federal State regulations",
  },
  {
    icon: <DriveFolderUploadIcon sx={{ color: "#2091F9", fontSize: "42px" }} />,
    title: "eVault",
    sectionId: "#section2",
    img: "./Images/evault_cloud.JPG",
    description:
      "Safeguarding and securing the electronic documents in digital vaults controlled by access rights",
  },
  {
    icon: <VideoChatIcon sx={{ color: "#2091F9", fontSize: "42px" }} />,
    title: "Video Conferencing",
    sectionId: "#section4",
    img: "./Images/vconf.JPG",
    description:
      "Enabling the Video Conferencing between parties to facilitate discussion to be part of digital agreement",
  },
];
