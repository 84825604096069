import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      fontFamily: "Poppins",
      color: "#fff",
      fontWeight: 800,
    },
    h2: {
      fontSize: "38px",
      fontFamily: "Poppins",
      fontWeight: 700,
    },
    h3: {
      fontSize: "28px",
      fontFamily: "Poppins",
    },
    h4: {
      fontSize: "24px",
      fontFamily: "Poppins",
    },
    h5: {
      fontSize: "20px",
      fontFamily: "Poppins",
    },
    h6: {
      fontSize: "18px",
      fontFamily: "Poppins",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Disable uppercase transformation
          borderRadius: "8px", // Custom border radius
          padding: "8px 16px", // Custom padding
          color: "#FFF",
          backgroundColor: "#2091F9",
          "&:hover": {
            backgroundColor: "#2091F9",
          },
        },
        containedPrimary: {
          backgroundColor: "#2091F9",
          "&:hover": {
            backgroundColor: "#2091F9",
          },
        },
        containedSecondary: {
          backgroundColor: "#2196f3",
          "&:hover": {
            backgroundColor: "#1976d2",
          },
        },
      },
    },
  },
});

export default theme;
