import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { makeStyles } from "@mui/styles";
import { featuresCardElements } from "./FeaturesCardElements";
import FeaturesCard from "./FeaturesCard";
import { motion } from "framer-motion";
import Title from "../../../ui/Title";
import DescriptionText from "../../../ui/DescriptionText";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItmes: "center",
    flexDirection: "column",
  },
}));

const variants = {
  hidden: { opacity: 0, type: "spring", scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.2,
      bounce: 0.8,
      duration: 1,
    },
  },
};

const Features = () => {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      padding={{ xs: 4, md: 7 }}
      sx={{ paddingTop: "24px !important" }}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Box>
        <Title title="Takeaways" color="#000000" />
        <DescriptionText
          text="DocVenture facilitates online Signature and Notary services "
          text2="easy, convenient, transparent and accessible from anywhere"
        />
      </Box>

      <motion.div
        variants={variants}
        viewport={{ once: false, amount: 0.8 }}
        style={{ width: "100%" }}
      >
        <Grid container spacing={3}>
          {featuresCardElements.map((item, index) => (
            <Grid
              xs={12}
              md={6}
              lg={3}
              key={item.title}
              mb={{ xs: "20px", lg: "0" }}
            >
              <FeaturesCard
                title={item.title}
                description={item.description}
                sectionId={item.sectionId}
                image={item.img}
              />
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Box>
  );
};

export default Features;
