import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/system/Unstable_Grid/Grid";
import React from "react";

const useStyles = makeStyles(() => ({
  card: {
    height: "350px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
    boxSizing: "border-box",
    width: "100%",
  },
}));

type SupportCardProps = {
  title: string;
  description: string;
  bgcolor: string;
};

const SupportCard: React.FC<SupportCardProps> = ({
  title,
  bgcolor,
  description,
}) => {
  const classes = useStyles();
  return (
    <Grid md={4} xs={12} mb={3}>
      <Box className={classes.card} sx={{ background: bgcolor }}>
        <Typography
          variant="h4"
          color="#FFF"
          fontSize={20}
          // fontWeight={600}
          align="center"
          py={3}
          sx={{
            background: "#979c9b",
            marginTop: "32px",
            width: "100%",
          }}
        >
          {title}
        </Typography>
        <Typography variant="h6" color="#FFF" mx={2} align="center">
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

export default SupportCard;
