import { Typography } from "@mui/material";
import React from "react";

type DescriptionTextPorps = {
  text: string;
  text2?: string;
  text3?: string;
  text4?: string;
};

const DescriptionText: React.FC<DescriptionTextPorps> = ({
  text,
  text2,
  text3,
  text4,
}) => {
  return (
    <Typography variant="h6" textAlign="center" fontWeight="300" mt={3}>
      {text}
      <br />
      {text2}
    </Typography>
  );
};

export default DescriptionText;
