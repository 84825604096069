import React from "react";
import Title from "../../../ui/Title";
import { Box } from "@mui/material";
import ProductComponent from "./ProductComponent";

const Vconference = () => {
  return (
    <Box padding={{ xs: 4, md: 7 }}>
      <Box mb={{ xs: "120px", md: "1px" }}>
        <Title title="Video Conferencing" color="#000000" />
      </Box>
      <ProductComponent
        isImageLeft={false}
        image="/Images/vconference.jpg"
        title="Video Conferencing"
        description="DocVenture has an in-built Calendar and Video Conference feature that allows anyone to schedule a meeting through Video Conference.   It can be recorded and saved for audit purpose, as an additional authenticity while doing online agreement. Video Conference allows to invite DocVenture users only through in-built Calander and Scheduler."
        description2="Video Conference brings in transparency, while understanding and agreeing to the contracting terms and conditions"
      />
    </Box>
  );
};

export default Vconference;
