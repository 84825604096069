import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

type ProductComponentProps = {
  isImageLeft: boolean;
  image: string;
  title: string;
  description: string;
  description2?: string;
};
const ProductComponent: React.FC<ProductComponentProps> = ({
  isImageLeft,
  image,
  title,
  description,
  description2,
}) => {
  const targetImageRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: targetImageRef,
    offset: ["start end", "end start"],
  });

  // const scale = useTransform(scrollYProgress, [0, 1], [0.85, 1]);
  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);

  return (
    <Box mt={7}>
      <Grid container>
        <Grid
          component="div"
          md={6}
          sx={{ display: { md: "none", xs: "block" } }}
        >
          <motion.div ref={targetImageRef} style={{ y }}>
            <motion.img src={image} alt="esign" style={{ width: "100%" }} />
          </motion.div>
        </Grid>
        {isImageLeft && (
          <Grid
            component="div"
            md={6}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            <motion.div ref={targetImageRef} style={{ y }}>
              <motion.img src={image} alt="esign" style={{ width: "90%" }} />
            </motion.div>
          </Grid>
        )}
        <Grid component="div" md={6} px={{ xs: "0px", md: "80px" }} gap={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "center" },
              alignItems: "flex-start",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              gap: "24px",
              marginTop: { xs: -10, md: 0 },
            }}
          >
            <Typography fontSize="14px" fontFamily="Poppins" lineHeight="30px">
              {description}
            </Typography>
            <Typography
              fontSize="18px"
              fontFamily="Poppins"
              lineHeight="30px"
              mt={1.5}
            >
              {description2}
            </Typography>
          </Box>
        </Grid>
        {!isImageLeft && (
          <Grid
            component="div"
            md={6}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            <motion.div ref={targetImageRef} style={{ y }}>
              <motion.img src={image} alt="esign" style={{ width: "90%" }} />
            </motion.div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProductComponent;
