import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Title from "../../../ui/Title";
import DescriptionText from "../../../ui/DescriptionText";
import FlipCard from "./FlipCard";
import theme from "../../../ui/theme";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SavingsIcon from "@mui/icons-material/Savings";
import ApprovalIcon from "@mui/icons-material/Approval";
import LockIcon from "@mui/icons-material/Lock";
import AssistantIcon from "@mui/icons-material/Assistant";
import ExtensionIcon from "@mui/icons-material/Extension";

const useStyles = makeStyles(() => ({
  blurContainer: {
    backgroundImage: "url('/values.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgr: "blur(5px)",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
    filter: "blur(6px)",
  },
  gridComponent: {
    display: "grid",
    gap: "28px",
    width: "100%",
    marginTop: "32px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

const values = [
  {
    title: "Hassle-Free Process",
    color: "#c45912",
    icon: <ChecklistIcon sx={{ fontSize: "48px" }} />,
    description: [
      "Simple and friendly UI",
      "Access from anywhere",
      "Web based and no Client installation",
    ],
  },
  {
    title: " Time & Cost Saving",
    color: "#c49212",
    icon: <SavingsIcon sx={{ fontSize: "48px" }} />,
    description: [
      "No Physical Presence or Appearance",
      "Less time for completion in Agreement Closing Process",
      "Low Cost for usage as Cloud based Solution",
    ],
  },
  {
    title: " Digitized Notarization",
    color: "#12b2c4",
    icon: <ApprovalIcon sx={{ fontSize: "48px" }} />,
    description: [
      "Appointing Authorized Notary Online",
      "Online eSigning & Authentication workflow",
      "Runs on Blockchain for more security",
    ],
  },
  {
    title: " Secured Vaulting",
    color: "#c4122f",
    icon: <LockIcon sx={{ fontSize: "48px" }} />,
    description: [
      "Digital Locker allocated for uploading documents",
      "Secured  Access Control for access ",
    ],
  },
  {
    title: "AI Smart Assistance",
    color: "#125fc4",
    icon: <AssistantIcon sx={{ fontSize: "48px" }} />,
    description: [
      "AI Chatbot for scanning Contract Agreement",
      "Instantaneous reply for simple queries",
    ],
  },
  {
    title: "Extensibility",
    color: "#125fc4",
    icon: <ExtensionIcon sx={{ fontSize: "48px" }} />,
    description: [
      "Easy Tailoring  UI",
      "Customizing functionality as per the Business requirement ",
      "Extending New Features",
    ],
  },
];

const Values = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        pb={6}
        padding={{ xs: 4, md: 7 }}
      >
        <Title title="Value Proposition" color="#000000" />
        <DescriptionText
          text="AI Assistance & Video Collaboration Saves Time, Effort and Cost"
          text2="Blockchain & Cloud based (SaaS) Digital Notary System"
        />
        <Typography
          variant="h2"
          fontWeight={100}
          fontFamily="Urbanist"
          mt={2}
          textAlign="center"
          fontSize={{ xs: 32, md: 38 }}
        >
          DocVenture Customizable and Extensible for your Business
        </Typography>

        <Box className={classes.gridComponent}>
          {values.map((item) => (
            // <CircleComponent
            //   key={item.color}
            //   text={item.title}
            //   color={item.color}
            // />

            <FlipCard
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Values;
