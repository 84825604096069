import React, { ReactNode, useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  flipCard: {
    backgroundColor: "transparent",
    perspective: "1000px",
    fontFamily: "sans-serif",
    width: "100%",
    height: "220px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
    margin: 0,
    fontFamily: "Poppins",
    color: "#fff",
  },
  flipCardInner: {
    position: "relative",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
    height: "100%",
    width: "100%",
  },
  flipCardHover: {
    transform: "rotateY(180deg)",
  },
  flipCardFrontBack: {
    boxShadow: "0 8px 14px 0 rgba(0,0,0,0.2)",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backfaceVisibility: "hidden",
    border: "1px solid #145db9",
    borderRadius: "1rem",
    padding: "10px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
  },
  flipCardFront: {
    background:
      "linear-gradient(120deg, #1074e5 60%, #1074e5 88%, #1074e5 40%, #2eaaff 48%)",
    color: "#d9f2ff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  flipCardBack: {
    background:
      // "linear-gradient(120deg, #2eaaff 30%, #2091f9 88%, #2091f9 40%, #1074e5 78%)",
      "#0F243E",
    color: "white",
    transform: "rotateY(180deg)",
  },
});

type FlipCardTypes = {
  title: string;
  description: string[];
  icon: ReactNode;
};

const FlipCard: React.FC<FlipCardTypes> = ({ title, description, icon }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <div
      className={classes.flipCard}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${classes.flipCardInner} ${
          hover ? classes.flipCardHover : ""
        }`}
      >
        <div
          className={`${classes.flipCardFrontBack} ${classes.flipCardFront}`}
        >
          <p>{icon}</p>
          <p className={classes.title}>{title}</p>
        </div>
        <div className={`${classes.flipCardFrontBack} ${classes.flipCardBack}`}>
          <ul>
            {description.map((item) => (
              <li
                key={item}
                style={{
                  color: "#FFF",
                  fontSize: "16px",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  marginBottom: "10px",
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
