import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MarkunreadIcon from "@mui/icons-material/Markunread";

const Footer = () => {
  return (
    <Box>
      <Grid
        container
        bgcolor="#252B42"
        height="100%"
        pt={7}
        padding={{ xs: 4, md: 7 }}
      >
        <Grid item md={6} display={{ xs: "none", md: "block" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <img alt="logo" src="/vassutech logo.svg" />
            <Typography color="#b9bdbd" fontFamily="Poppins" align="center">
              The DocVenture is a product of{" "}
              <a
                style={{ color: "#2081e9", textDecoration: "none" }}
                href="https://www.vassutech.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                VassuTech
              </a>{" "}
              Services Inc
            </Typography>

          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          color="#b9bdbd"
          flexDirection="column"
          gap={2}
        >
          <Box display={{ md: "none", xs: "block" }} width="100%" mb={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img alt="logo" src="/vassutech logo.svg" />
              <Typography color="#b9bdbd" fontFamily="Poppins" align="center">
                
				
              </Typography>
			  
            </Box>
          </Box>
          <Typography align="center" fontSize={20}>
            VassuTech Services Inc.
          </Typography>
          <Box display="flex" justifyContent="center" gap={1}>
            <RoomOutlinedIcon />
            <Typography align="center">
              Venture Labs, 3600 Steeles Ave. E. Room B114, Markham, Canada.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <MarkunreadIcon />
            <Typography align="center">info@vassutech.com</Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <SmartphoneIcon sx={{ align: "center" }} />
            <Typography align="center">+1 (647) 242-6434</Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <XIcon />
            <FacebookIcon />
            <LinkedInIcon />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "centes",
          padding: "24px",
          gap: "16px",
          color: "#b9bdbd",
          background: "#252B42",
        }}
      >
        <Typography>Copyright © 2024. VassuTech.</Typography>
        <Typography>Terms & Conditions Privacy Policy</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
