import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loader: {
    display: "flex",
  },
  dot: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    border: "2px solid #e8e8e8",
    background: "#16b0c1",
    animation: "$jump 0.8s ease-in-out infinite alternate",
  },
  "@keyframes jump": {
    "100%": {
      background: "#661e92",
      transform: "translateZ(-3rem) scale(1.9)",
    },
  },
  dot1: {
    animationDelay: "0.1s",
  },
  dot2: {
    animationDelay: "0.2s",
  },
  dot3: {
    animationDelay: "0.3s",
  },
  dot4: {
    animationDelay: "0.4s",
  },
  dot5: {
    animationDelay: "0.5s",
  },
});

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader} style={{ marginTop: "24px" }}>
      <div className={`${classes.dot} ${classes.dot1}`}></div>
      <div className={`${classes.dot} ${classes.dot2}`}></div>
      <div className={`${classes.dot} ${classes.dot3}`}></div>
      <div className={`${classes.dot} ${classes.dot4}`}></div>
      <div className={`${classes.dot} ${classes.dot5}`}></div>
    </div>
  );
};

export default Loader;
