import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";

const FormAlert = ({
  setOpen,
  setIsFormSubmitted,
}: {
  setOpen: any;
  setIsFormSubmitted: any;
}) => {
  const handleClick = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        padding: { xs: "18px 0px", md: "24px" },
        height: { xs: "100%", md: "240px" },
        width: { xs: "100%", md: "400px" },
      }}
    >
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <CheckCircleOutlineIcon sx={{ color: "#07ab12", fontSize: "100px" }} />
      </motion.div>
      <br />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.25 } }}
      >
        <Typography
          fontSize="14px"
          fontFamily="Poppins"
          align="center"
          fontWeight={500}
          px={3}
        >
          Thank you for showing your interest and submitting request. We will
          connect within two business days
        </Typography>
      </motion.div>
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.5 } }}
        onClick={handleClick}
        style={{
          marginTop: "24px",
          padding: "10px 20px",
          borderRadius: "4px",
          border: "none",
          outline: "none",
          background: "#007cfb",
          color: "#fff",
          cursor: "pointer",
        }}
      >
        Close
      </motion.button>
    </Box>
  );
};

export default FormAlert;
