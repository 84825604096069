import React from "react";
import Title from "../../../ui/Title";
import SupportCard from "./SupportCard";
import { Box } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";

const supportContent = [
  {
    title: "Adaptive Maintenance",
    description:
      "The product will be upgraded to meet critical software parameters and security",
    bgColor: "#e0a014",
  },
  {
    title: "Product Support",
    description:
      "We provide continuous support and service without impacting your business",
    bgColor: "#e04414",
  },
  {
    title: "Product Training",
    description:
      "Training will be provided to Business Users by our Technical Team during implementation",
    bgColor: "#05295c",
  },
];

const Support = () => {
  return (
    <Box
      padding={{ xs: 4, md: 7 }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Title title="Service & Support" color="#000000" />
      <Grid
        container
        spacing={2}
        mt={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {supportContent.map((item) => (
          <SupportCard
            bgcolor={item.bgColor}
            key={item.title}
            title={item.title}
            description={item.description}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default Support;
