import { ThemeProvider } from "@emotion/react";
import theme from "../src/Components/ui/theme";
import LandingPage from "../src/Components/LandingPage/LandingPage";
import React from "react";

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <LandingPage />
      </ThemeProvider>
    </div>
  );
};

export default App;
