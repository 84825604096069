import { Box, Dialog, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import BannerLogo from "./BannerLogo";
import theme from "../../../ui/theme";
import ModalForm from "./ModalForm";
import FormAlert from "./FormAlert";

const useStyles = makeStyles(() => ({
  button: {
    background: "#ffffff !important",
    color: "#2187d6",
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: "600",
    fontSize: "18px !important",
    padding: "18px 32px",
    border: "none",
    outline: "none",
    marginTop: "28px",
    cursor: "pointer",
    width: "200px",
  },
  title: {
    position: "relative",
    animation: `$fallingText 3s ease`,
  },
  "@keyframes fallingText": {
    "0%": {
      top: "-200px",
    },
    "100%": {
      top: "0px",
    },
  },

  "@keyframes imageRotation": {
    "0%": {
      transform: "rotateZ(8deg) rotateY(0deg) rotateX(0deg)",
    },
    "25%": {
      transform: "rotateZ(8deg) rotateY(90deg) rotateX(0deg)",
    },
    "40%": {
      transform: "rotateZ(8deg) rotateY(180deg) rotateX(0deg)",
    },
    "60%": {
      transform: "rotateZ(8deg) rotateY(90deg) rotateX(0deg)",
    },

    "75%": {
      transform: "rotateZ(8deg) rotateY(0deg) rotateX(0deg)",
    },
    "85%": {
      transform: "rotateZ(8deg) rotateY(0deg) rotateX(0deg)",
    },
    "100%": {
      transform: "rotateZ(8deg) rotateY(0deg) rotateX(0deg)",
    },
  },
  blurContainer: {
    backgroundImage: "url('/Images/background banner2.JPG')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  topContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignContent: "center",
    padding: "0px 64px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "100px",
      height: "100%",
      padding: "0px 32px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
  },
  bannerContainer: {
    position: "relative",
    height: "99vh",
    overflow: "hidden",
    width: "100%",
    boxSizing: "border-box",
  },
  closeButton: {
    backgrouns: "transparent",
  },
}));

const variants = {
  visible: {
    opacity: 1,
    x: 0,

    transition: {
      staggerChildren: 0.25,
      type: "spring",
      stiffness: 28,
    },
  },
  hidden: {
    opacity: 0,
    x: -1500,
  },
};

const Banner = () => {
  const bannerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: bannerRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [180, -180]);

  const [open, setOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleFormDialog = () => {
    setIsFormSubmitted(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsFormSubmitted(false);
  };

  const classes = useStyles();
  return (
    <>
      <Box className={classes.bannerContainer} px={{ xs: 4, md: 0 }}>
        <motion.div
          ref={bannerRef}
          className={classes.blurContainer}
          style={{ y }}
        />
        <Box className={classes.topContainer}>
          <Grid container>
            <Grid item lg={6.5}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variants}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <BannerLogo />
                <motion.div variants={variants}>
                  <Typography
                    variant="h1"
                    fontWeight="300"
                    //fontSize="40px"
                    fontSize={{ xs: "20px", sm: "38px", md: "46px" }}
                    mt={2}
                    sx={{
                      textShadow: " 2px 2px 4px rgba(0, 0, 0, 0.7)",
                      // fontSize: { xs: "px" },
                    }}
                  >
                    AI based Digital Agreement Platform
                  </Typography>
                </motion.div>
                <motion.div variants={variants}>
                  <Typography
                    variant="h2"
                    fontWeight="300"
                    color="#FFF"
                    fontSize={{ xs: "14px", sm: "16px", md: "22px" }}
                    sx={{
                      textShadow: " 2px 2px 4px rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    DocVenture Simplifies Digital Contract Agreement and Signing
                  </Typography>
                </motion.div>
              </motion.div>
            </Grid>
            <Grid
              item
              lg={5.5}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box
                display={{ lg: "flex" }}
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                width="90%"
                ml={{ md: 5 }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 2.5,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  <motion.button
                    whileTap={{ scale: 0.8, rotate: "5deg" }}
                    className={classes.button}
                    onClick={handleFormDialog}
                  >
                    Request Demo
                  </motion.button>
                </motion.div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={open} scroll="body" onClose={handleClose}>
          <Box sx={{ transition: "0.3s ease" }}>
            {isFormSubmitted ? (
              <FormAlert
                setOpen={setOpen}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            ) : (
              <ModalForm
                setIsFormSubmitted={setIsFormSubmitted}
                setOpen={setOpen}
              />
            )}
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default Banner;
